<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :loading="loading">

    <template #title-left>Изменение кармы игрока {{ player ? player.name : '' }}</template>

    <template #actions>
      <v-btn :disabled="btnOK_disabled" text @click="closeOK">
        OK
      </v-btn>
    </template>

    <!--    <div>Текст по центру</div>-->

    <v-row>

      <v-col cols="12">
        <v-subheader class="pl-0">
          Ваши баллы активности {{ display_activity }}:
        </v-subheader>
        <v-slider
            v-model="slider_value"
            :max="slider_max"
            thumb-label
        >
        </v-slider>

        <v-radio-group
            v-model="karma_direct"
            row
        >
          <v-radio
              label="Осужаю"
              :value=-1
          ></v-radio>
          <v-radio
              label="Одобряю"
              :value=1
          ></v-radio>
        </v-radio-group>

        <v-subheader class="pl-0">
          Карма игрока {{ player_karma_new }}:
        </v-subheader>
        <v-progress-linear
            height="10"
            class="mb-4 opacity"
            background-color="tertiary"
            color="primary"
            :value="progress_value"
            rounded
        ></v-progress-linear>


        <v-text-field
            v-model="server.message"
            label="Сообщение для игрока"
            maxlength="25"
        ></v-text-field>

        <v-checkbox
            v-model="server.anonim"
            label="Анонимное сообщение"
        ></v-checkbox>


      </v-col>

    </v-row>


  </v.dialog>
</template>

<script>
let normal_data = {}

// var karma = {}
// karma.active = 3000
// karma.interval = [-10000, 10000]
// let player_karma = -100

// function scale(number) {
//   return (number - karma.interval[0]) * (100 - 1) / (karma.interval[1] - karma.interval[0]) + 1;
// }


export default {
  props: {value: Boolean, player: Object, params: Object},
  data: function () {
    return {
      server: {message: '', value: 0, anonim: false},
      slider_value: 0,
      btnOK_disabled: true,
      anonim: false,
      display_activity: 0,
      loading: false,
      karma_direct: '',
      slider_max: 0,
      progress_value: 50,
      player_karma_new: 0
//      progress_value: scale(this.player.karma.score, this.params.karma_interval[0], this.params.karma_interval[1] ),

    }
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        if (!this.value) return // отрабатываем только закрытие формы
        this.$emit('input', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  watch: {
    slider_value(newvalue) {
      if (this.karma_direct === '') return
      this.btnOK_disabled = false
      this.player_karma_new = this.player.karma.score + newvalue * this.karma_direct
      this.progress_value = this.$lib.mapping(this.player_karma_new, this.params.karma_interval)
      this.display_activity = this.slider_max - newvalue
    },
    // params: {
    //   immediate: true, // немедленный запуск обработки
    //   deep: true, // глубокое наблюдение за объектом
    //   handler(newValue, oldValue) {
    //     newValue; oldValue
    //     window.console.log('params', newValue)
    //     this.slider_max = newValue.activityCredit
    // }
    // }
  },
  methods: {
    form_show() {
      window.console.log('normal_data', normal_data)
      this.$lib.restore(this.$data, normal_data)
      // Очищаем форму
      // Object.assign(this.$data, normal_data);
      // let aa =  this.$lib.copy(normal_data)
      // Object.keys(normal_data).forEach(k => this.$data[k] = aa[k])
      // Заполням форму
      this.slider_max = this.params.activityCredit
      this.display_activity = this.slider_max
      if (!this.player.karma.score) return
      this.player_karma_new = this.player.karma.score
      this.progress_value = this.$lib.mapping(this.player.karma.score, this.params.karma_interval)
    },
    closeOK() {
      this.loading = true
      // отправляем значение активности пользователя, чтобы рассчитать карму на сервере
      this.server.value = this.slider_value * this.karma_direct
      this.$axios.post(this.$url.server_user_karma.replace('55555', this.player.id), this.server)
          .then(response => {
            this.player.karma = response.data
            this.$emit('closeOK', this.player)// чтобы пользователь обработал закрытие
            this.params.activityCredit -= this.slider_value
            this.loading = false
            this.show = false
          })
    },
    // form_close() {
    //
    // },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
  }
}
</script>
